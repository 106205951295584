import { enGB, enUS } from "date-fns/locale";

export default {
  // This is the list of languages your application supports
  supportedLngs: ["en-US", "en-GB", "cimode"],
  // This is the language you want to use in case
  // if the user language is not in the supportedLngs
  fallbackLng: "en-US",
  // The default namespace of i18next is "translation", but you can customize it here
  defaultNS: "common",
};

export function getDateFnLocale(localeCode: string) {
  const locales = {
    "en-GB": enGB,
    "en-US": enUS,
  };
  return locales[localeCode as "en-GB" | "en-US"];
}
