import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import * as Sentry from "@sentry/remix";
import { setDefaultOptions } from "date-fns";
import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { useEffect } from "react";
import { hydrateRoot } from "react-dom/client";
import { I18nextProvider, initReactI18next } from "react-i18next";
import { getInitialNamespaces } from "remix-i18next/client";
import i18n, { getDateFnLocale } from "./i18n";

type GlobalParamsFromServer = "datadogEnv" | "version";

if (!("__envContext" in window) || typeof window.__envContext === "undefined") {
  throw new Error("__envContext not passed");
}

const envContext =
  (window.__envContext as unknown as Partial<
    Record<GlobalParamsFromServer, string>
  >) ?? {};

Sentry.init({
  dsn: "https://8d220f5661b44d87b0342a2dce2439d6@o4507866573701120.ingest.us.sentry.io/4507866578354176",
  tracesSampleRate: 0.5,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
  // Not set in local envs
  enabled: !!envContext.datadogEnv,
  environment: envContext.datadogEnv,
  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
    Sentry.replayIntegration(),
  ],
});

const hydrate = () => {
  i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(Backend)
    .init({
      ...i18n,
      ns: getInitialNamespaces(),
      backend: {
        loadPath: `/locales/{{lng}}/{{ns}}.json?v=${
          envContext.version === "local-dev"
            ? Math.random()
            : envContext.version
        }`,
      },
      detection: { order: ["htmlTag"], caches: [] },
    })
    .then(() => {
      setDefaultOptions({
        locale: getDateFnLocale(i18next.language),
      });
      hydrateRoot(
        document,
        <I18nextProvider i18n={i18next}>
          <RemixBrowser />
        </I18nextProvider>,
      );
    });
};

if (window.requestIdleCallback) {
  window.requestIdleCallback(hydrate);
} else {
  // Safari doesn't support requestIdleCallback
  // https://caniuse.com/requestidlecallback
  window.setTimeout(hydrate, 1);
}
